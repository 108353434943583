/* 12.04 Skill With Video CSS */
.skill-section-padding-bottom{
    padding-bottom: 190px;
    // Responsive
    @media #{$laptop-device} {
        padding-bottom: 150px;
    }
    @media #{$desktop-device} {
        padding-bottom: 200px;
    }
    @media #{$tablet-device} {
        padding-bottom: 50px;
    }

    @media #{$large-mobile} {
        padding-bottom: 30px;
    }
}

.video-section{
    position: relative;
    z-index: 999;
    & .shape {
        position: absolute;
        z-index: -1;

        &-1 {
            top: 0;
            left: 0;
            right: 0;
            text-align: center;
            margin: 0 auto;
        }
    }

    // Responsive
    @media #{$large-mobile}{
        & .shape {
    
            &-1 {
                width: 250px;
            }
        }
    }
}

.agency-list{
    list-style: none;
    padding-left: 0;
    margin-bottom: 35px;
    li{
        display: flex;
        align-items: center;
        & + li{
            margin-top: 10px;
        }
        .icon{
            margin-right: 19px;
            font-size: 15px;
            color: var(--clr-primary);
        }
    }
}

.video-popup-area{
    position: relative;
    z-index: 9;
    // Responsive
    @media #{$tablet-device}{
        margin-top: 50px;
    }
    @media #{$large-mobile}{
        margin-top: 50px;
    }
    img{
        border-radius: 5px;
    }

    & .shape {
        position: absolute;
        z-index: 1;

        &-1 {
            left: -40px;
            bottom: -60px;
            z-index: -1;
        }
    }

    // Responsive
    @media #{$tablet-device}{
        & .shape {
    
            &-1 {
                left: 0px;
                bottom: -20px;
                z-index: -1;
            }
        }
    }
    @media #{$large-mobile}{
        & .shape {
    
            &-1 {
                left: 0px;
                bottom: -20px;
                z-index: -1;
            }
        }
    }
}

.skill-video {
    position: relative;
    z-index: 9;

    display: block;
    overflow: hidden;

    width: 100%;
    margin: auto;

    border-radius: 5px;

    & .image {
        width: 100%;

        transition: all 1.5s cubic-bezier(0, 0, 0.2, 1);
    }

    & .icon {
        position: absolute;
        z-index: 9;
        top: 50%;
        left: 50%;
        width: 90px;
        height: 90px;
        line-height: 90px;
        background-color: rgba(255,255,255,0.8);
        border: 0;
        transform: translate(-50%, -50%);
        text-align: center;
        border-radius: 50%;
        i{
            font-size: 24px;
            color: var(--clr-primary);
        }
    }

    &:hover {
        & .image {
            transform: scale(1.1);
        }
    }
}

.play-btn {
    width: 90px;
    height: 90px;
    line-height: 90px;
    background-color: rgba(255,255,255,0.8);
    text-align: center;
    border-radius: 50%;
    margin: auto;
    border: 0;
    transition: var(--transition);
    i{
        font-size: 24px;
        color: var(--clr-primary);
    }
    &:hover{
        transform: scale(1.04);
    }
}

.skill-image-area{
    margin-right: 0;
    & .shape {
        position: absolute;
        z-index: 1;

        &-1 {
            top: auto;
            bottom: 60px;
            right: -35px;
            z-index: -1;
        }
    }

    // Responsive
    @media #{$laptop-device}{
        & .shape {
    
            &-1 {
                top: auto;
                bottom: 60px;
                right: 0px;
            }
        }
    }
    @media #{$desktop-device}{
        & .shape {
    
            &-1 {
                top: auto;
                bottom: 40px;
                right: 0px;
            }
        }
    }
    @media #{$tablet-device}{
        & .shape {
    
            &-1 {
                top: auto;
                bottom: 30px;
                right: 0px;
            }
        }
    }
    @media #{$large-mobile}{
        & .shape {
    
            &-1 {
                top: auto;
                bottom: 30px;
                right: 0px;
            }
        }
    }

}


/* Progress Bar CSS */

.progress-bar {
    &--one {
        & .progress-charts {
            margin-bottom: 20px;
            padding-bottom: 20px;
            overflow: hidden;
        }
    }
}
.progress-charts {
    & .heading {
        font-size: 16px;
        line-height: 23px;
        color: var(--clr-heading);
        margin-bottom: 12px;
        font-weight: 400;
    }
    
    .progress{
        --bs-progress-height: 4px;
        --bs-progress-font-size: 14px;
        --bs-progress-bg: #748494;
        --bs-progress-border-radius: 0;
        overflow: visible;
        .progress-bar{
            overflow: visible;
            position: relative;
            background: var(--clr-primary);
            color: var(--clr-primary);
            &::after,&::before {
                content: '';
                position: absolute;
                right: -4px;
                top: 50%;
                border-radius: 50%;
                transform: translate(50%, -50%);
            }

            &::after {
                height: 13px;
                width: 13px;
                border: 3px solid currentColor;
                background-color: var(--clr-white);
            }

            &::before {
                height: 28px;
                width: 28px;
                border: 1px solid currentColor;
                opacity: .3;
            }
            span{
                position: absolute;
                right: -45px;
                font-size: 14px;
                font-weight: 500;
                top: -40px;
                color: #2e3280;
                line-height: 2;
            }
        }
        &.gradient{
            &-1{
                .progress-bar{
                    color: #C1262D;
                    background-color: #C1262D;
                    background-image: #C1262D;
                   
                }
            }
            &-2{
                .progress-bar{
                    color: #DC8630;
                    background-color: #DC8630;
                    background-image: #DC8630;    
                }
            }
            &-3{
                .progress-bar{
                    color: #3096CA;
                    background-color: #3096CA;
                    background-image: #3096CA; 
                }
            }
            &-4{
                .progress-bar{
                    color: #55AA48;
                    background-color: #55AA48;
                    background-image: #55AA48;
                }
            }
        }
    }
}